.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav [class*='owl-'] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background: #4DC7A0;
  color: #FFF;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-carousel .owl-dot {
  display: inline-block;
  zoom: 1;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: $secondary-background-color;
}

.owl-theme .owl-nav [class*='owl-'] {
  background-color: $base-background-color;
  color: $base-font-color;

  &:hover {
    background-color: $secondary-background-color;
    color: $secondary-font-color;
  }
}

.owl-carousel {
  .owl-item {
    transform: translateZ(-100px);
    .carousel {
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
      }
    }
  }
}

.carousel-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.1);
  color: $base-font-color;
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    text-align: center;
  }
}

.loading-placeholder {
  width: 100vw;
  height: 34vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-overlay {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
}
