table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

th {
  font-weight: 600;
  padding: $small-spacing 0;
  text-align: center;
}

td {
  padding: $small-spacing 0;
}

tr,
td,
th {
  vertical-align: middle;
}
