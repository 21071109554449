@include keyframes("slide-in-right") {
  from {
    transform: translateX(30vw);
  }
  to {
    transform: translateX(0);
  }
}

@include keyframes("slide-out-right") {
  from {
    transform: translateX(0vw);
  }
  to {
    transform: translateX(35vw);
  }
}

.toast {
  position: fixed;
  right: 1em;
  left: 70%;
  top: calc(1em + #{$navbar-height-s});
  @include media(min-width $large-screen) {
    top: calc(1em + #{$navbar-height-l});
  }
  text-align: center;
  z-index: 300;
  padding: 1em;
  font-size: 0.9em;
  @include shadow(1);
  animation: 400ms slide-in-right linear;
  p {
    margin: 0;
    padding: 0;
  }

  &.success {
    background-color: $success-color;
    color: white;
  }

  &.out {
    animation: 400ms slide-out-right linear;
  }
}