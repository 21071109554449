.filter-bar-mobile {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: darken($base-background-color, 5%);
  margin: 0.5em 0 1em 0;
   a {
     display: block;
     padding: 1em;
     flex-grow: 1;
     text-align: center;
     &:hover {
       background-color: $secondary-color;
       color: white;
     }
   }

  @include media(min-width $large-screen) {
    display: none;
  }
}

.filterbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-bottom: 1em;
  padding: 0 1em;
  text-transform: uppercase;
  flex-wrap: wrap;

  @include media(max-width $large-screen) {
    display: none;
  }
  a, button, select {
    font-weight: 400;
    background-color: transparent;
    color: $base-font-color;
    margin: 0 0.5em;

    &:focus, &:active {
      border: none;
      box-shadow: none;
      background-color: $secondary-background-color;
      color: $secondary-font-color;
    }
  }

  span {
    margin: 0 1em 0 0;
  }
    form {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      input, select, label {
        margin: 0;
        border: none;
      }
      .sort {
        display: flex;
        flex-direction: row;
      }
    }
}

.search-box {
  margin: 0 1em;

  input {
    border: none;
    box-shadow: none;
    border-bottom: 1px solid $secondary-background-color;
    &:focus, &:active {
      border: none;
      border-bottom: 1px solid $secondary-background-color;
      box-shadow: none;
      background-color: transparent;
    }
  }
}

.sort-box {
  margin: 0 1em 1em 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .smaller {
    font-size: 0.8em;
  }

  a, button, select {
    font-weight: 400;
    background-color: transparent;
    color: $base-font-color;
    margin: 0 0.5em;
  }
}

.sub-categories-bar {
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  a.btn {
    margin: 0.5em;
    background-color: transparent;
    color: $base-font-color;
    &.active {
      background-color: $secondary-background-color;
      color: $secondary-font-color;
    }
  }
}