@font-face {
  font-family: 'SilverThreadJF';
  src: url("../fonts/SilverThreadJF.otf");
  src: url("../fonts/SilverThreadJF.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  background-color: $base-background-color;
  font-smooth: anti-aliassing;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include media(max-width $font-breakpoint) {
    font-size: $base-font-size-mobile;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: modular-scale(1);
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}

p {
  margin: 0 0 $small-spacing;
}

a {
  color: $action-color;
  text-decoration: none;
  transition: color $base-duration $base-timing;

  &:active,
  &:focus,
  &:hover {
    color: shade($action-color, 25%);
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

strong {
  font-weight: bold;
}
