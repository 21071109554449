@include keyframes(fadeOut) {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@include keyframes(fadeIn) {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeOut {
  @include animation(fadeOut)
}

.animated  {
  -webkit-animation-duration : 1ms  ;
  animation-duration : 1ms  ;
  -webkit-animation-fill-mode : both  ;
  animation-fill-mode : both  ;
}

section.item {
  @include outer-container(100%);
  padding: 2em;
  h1 {
    text-transform: uppercase;
  }

  .carousel-wrapper {
    float: left;
    display: block;
    width: 100%;
    text-align: left;

    @include media(min-width $large-screen) {
      width: 50%;
    }

    .owl-carousel {
      .owl-nav {
        margin: 0;
      }

      .owl-dots {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
      }

      .owl-prev {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        background-color: transparent;
        border: none;
        margin: 0;
        padding: 2em;
        span {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 1em;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        i {
          font-size: 2em;
        }
      }

      .owl-next {
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        background-color: transparent;
        border: none;
        margin: 0;
        padding: 2em;
        span {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 1em;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        i {
          font-size: 2em;
        }
      }
    }

    h1 {
      padding: 0.5em;
      font-size: 1.5em;
    }
  }

  .thumbnails {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
    max-width: 50%;

    .thumbnail {
      display: flex;
      align-items: center;
      margin: 0.2em;
      background-color: #eee;
      width: 5em;
      cursor: pointer;
      img {
        display: block;
      }
    }
  }

  article {
    float: left;
    display: block;
    padding: 3em 0;
    width: 100%;
    font-weight: 300;
    font-size: 1.2em;

    @include media(min-width $large-screen) {
      width: 50%;
      padding: 0 3em;
    }

    .description {
      text-align: justify;
      margin-bottom: 5em;
    }
  }

  .contact {
    float: left;
    display: block;
    width: 100%;
    margin-top: 5em;
    position: relative;
    padding: 2em;

    @include media(min-width $large-screen) {
      width: 80%;
      margin-left: 10%;
    }
    #form-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      justify-content: center;
      align-items: center;
      background-color: $base-background-color;
      display: none;
      &.show {
        display: flex;
      }
    }

    h3 {
      font-weight: 400;
    }
    .errors {
      color: $error-color;
      h4 {
        font-weight: normal;
        font-size: 0.9em;
        padding: 0 0 1em 0;
        margin: 0;
      }
      .errors {
        color: $error-color;
      }
    }

    form {
      &.transparent {
        * {
          &::placeholder {
            color: $base-background-color;
          }
          color: $base-background-color;
        }
      }
    }
  }
}
