html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

button, input[type="button"], input[type="reset"], input[type="submit"], .btn {
  appearance: none;
  background-color: #292d35;
  border: 0;
  border-radius: 0px;
  color: #fafafa;
  cursor: pointer;
  display: inline-block;
  font-family: "Open Sans", "Helvetica", sans-serif;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1;
  padding: 0.75em 1.5em;
  text-decoration: none;
  transition: background-color 150ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  button:hover, button:focus, input[type="button"]:hover, input[type="button"]:focus, input[type="reset"]:hover, input[type="reset"]:focus, input[type="submit"]:hover, input[type="submit"]:focus, .btn:hover, .btn:focus {
    background-color: #21242a;
    color: #fff; }
  button:disabled, input[type="button"]:disabled, input[type="reset"]:disabled, input[type="submit"]:disabled, .btn:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
    button:disabled:hover, input[type="button"]:disabled:hover, input[type="reset"]:disabled:hover, input[type="submit"]:disabled:hover, .btn:disabled:hover {
      background-color: #292d35; }
  button.active, button.selected, input[type="button"].active, input[type="button"].selected, input[type="reset"].active, input[type="reset"].selected, input[type="submit"].active, input[type="submit"].selected, .btn.active, .btn.selected {
    background-color: #292d35;
    color: #fafafa; }

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0; }

button {
  text-transform: capitalize; }

legend {
  font-weight: 600;
  margin-bottom: 0.375em;
  padding: 0; }

label {
  display: block;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 0.375em; }

input,
select,
textarea {
  display: block;
  font-family: "Open Sans", "Helvetica", sans-serif;
  font-size: 1em; }

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input:not([type]), textarea,
select[multiple] {
  background-color: white;
  border: 1px solid #3f4552;
  border-radius: 0px;
  box-shadow: none;
  box-sizing: border-box;
  margin-bottom: 0.75em;
  padding: 0.5em;
  transition: border-color 150ms ease;
  width: 100%; }
  input[type="color"]:hover, input[type="date"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="email"]:hover, input[type="month"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="time"]:hover, input[type="url"]:hover, input[type="week"]:hover, input:not([type]):hover, textarea:hover,
  select[multiple]:hover {
    border-color: #333841; }
  input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus, input:not([type]):focus, textarea:focus,
  select[multiple]:focus {
    border-color: #292d35;
    box-shadow: none;
    outline: none; }
  input[type="color"]:disabled, input[type="date"]:disabled, input[type="datetime"]:disabled, input[type="datetime-local"]:disabled, input[type="email"]:disabled, input[type="month"]:disabled, input[type="number"]:disabled, input[type="password"]:disabled, input[type="search"]:disabled, input[type="tel"]:disabled, input[type="text"]:disabled, input[type="time"]:disabled, input[type="url"]:disabled, input[type="week"]:disabled, input:not([type]):disabled, textarea:disabled,
  select[multiple]:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed; }
    input[type="color"]:disabled:hover, input[type="date"]:disabled:hover, input[type="datetime"]:disabled:hover, input[type="datetime-local"]:disabled:hover, input[type="email"]:disabled:hover, input[type="month"]:disabled:hover, input[type="number"]:disabled:hover, input[type="password"]:disabled:hover, input[type="search"]:disabled:hover, input[type="tel"]:disabled:hover, input[type="text"]:disabled:hover, input[type="time"]:disabled:hover, input[type="url"]:disabled:hover, input[type="week"]:disabled:hover, input:not([type]):disabled:hover, textarea:disabled:hover,
    select[multiple]:disabled:hover {
      border: 1px solid #3f4552; }
  input[type="color"]::placeholder, input[type="date"]::placeholder, input[type="datetime"]::placeholder, input[type="datetime-local"]::placeholder, input[type="email"]::placeholder, input[type="month"]::placeholder, input[type="number"]::placeholder, input[type="password"]::placeholder, input[type="search"]::placeholder, input[type="tel"]::placeholder, input[type="text"]::placeholder, input[type="time"]::placeholder, input[type="url"]::placeholder, input[type="week"]::placeholder, input:not([type])::placeholder, textarea::placeholder,
  select[multiple]::placeholder {
    color: rgba(0, 0, 0, 0.8);
    text-transform: capitalize; }

textarea {
  resize: vertical; }

[type="search"] {
  appearance: none; }

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: 0.375em; }

[type="file"] {
  margin-bottom: 0.75em;
  width: 100%; }

select {
  margin-bottom: 0.75em;
  max-width: 100%;
  width: auto;
  appearance: none;
  background-color: #292d35;
  color: #fafafa;
  border: none; }

html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.hidden {
  display: none !important; }

section {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  transform: translateZ(100px); }
  section::after {
    clear: both;
    content: "";
    display: block; }
  section.more-info {
    padding: 4em 0 4em 0;
    margin: 2em 0 0 0;
    background-color: #f7f8fa; }
    section.more-info h1 {
      padding: 0 0 1em 0;
      background-color: #f7f8fa; }
  section .row-wrapper {
    display: block; }
    section .row-wrapper::after {
      clear: both;
      content: "";
      display: block; }
  section h1 {
    padding: 2em;
    text-align: center;
    margin: 0;
    font-weight: 300;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    transform: translateZ(100px);
    font-size: 2em; }
    @media screen and (max-width: 600px) {
      section h1 {
        font-size: 1.5em;
        padding: 1em 0.5em; } }
    section h1 .line {
      width: 15%;
      height: 1px;
      margin: 0 1em;
      background-color: rgba(0, 0, 0, 0.8); }
      @media screen and (max-width: 600px) {
        section h1 .line {
          width: 40%; } }
  section article.item {
    margin: 0;
    padding: 1em;
    padding-top: 0;
    padding-bottom: 2em;
    float: left;
    display: block;
    margin-right: 0%;
    width: 100%; }

@-webkit-keyframes item-animation {
  from {
    transform: translateX(-100vw); }
  to {
    transform: translateX(0); } }

@-moz-keyframes item-animation {
  from {
    transform: translateX(-100vw); }
  to {
    transform: translateX(0); } }

@keyframes item-animation {
  from {
    transform: translateX(-100vw); }
  to {
    transform: translateX(0); } }

@-webkit-keyframes item-animation-out {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(100vw); } }

@-moz-keyframes item-animation-out {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(100vw); } }

@keyframes item-animation-out {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(100vw); } }
    section article.item:last-child {
      margin-right: 0; }
    @media screen and (min-width: 600px) {
      section article.item {
        float: left;
        display: block;
        margin-right: 0%;
        width: 50%; }
        section article.item:last-child {
          margin-right: 0; } }
    @media screen and (min-width: 1200px) {
      section article.item {
        float: left;
        display: block;
        margin-right: 0%;
        width: 25%; }
        section article.item:last-child {
          margin-right: 0; } }
    section article.item figure {
      position: relative;
      overflow: hidden; }
      section article.item figure img {
        vertical-align: top;
        -webkit-transition: all 300ms ease-out;
        -moz-transition: all 300ms ease-out;
        transition: all 300ms ease-out; }
    section article.item:hover figcaption, section article.item:focus figcaption {
      opacity: 1; }
    section article.item:hover img, section article.item:focus img {
      transform: scale(1.25); }
    section article.item:hover .line, section article.item:focus .line {
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      transform: scale(1, 1); }
    section article.item figcaption {
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fafafa; }
      section article.item figcaption h3 {
        border-bottom: none;
        font-weight: 200;
        font-size: 1.4em;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 1px;
        margin: 5px; }
      section article.item figcaption a {
        margin: 5px;
        font-weight: 200; }
      section article.item figcaption .line {
        transform: scale(0, 0);
        width: 80%;
        height: 1px;
        background-color: white;
        margin: 10px 0;
        -webkit-transition: all 300ms ease-out;
        -moz-transition: all 300ms ease-out;
        transition: all 300ms ease-out; }
  section article.info {
    float: left;
    display: block;
    margin-right: 0%;
    width: 100%;
    margin: 0;
    padding: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: 0.3s all linear; }
    section article.info:last-child {
      margin-right: 0; }
    section article.info:hover .fa, section article.info:hover h3 {
      transform: scale(1); }
    section article.info .fa, section article.info h3 {
      transition: 0.3s all linear;
      transform: scale(0.8); }
    section article.info .fa {
      font-size: 5em; }
    section article.info h3 {
      margin-top: 25px;
      font-weight: 200; }
    @media screen and (min-width: 600px) {
      section article.info {
        float: left;
        display: block;
        margin-right: 0%;
        width: 50%;
        display: flex; }
        section article.info:last-child {
          margin-right: 0; } }
    @media screen and (min-width: 1200px) {
      section article.info {
        float: left;
        display: block;
        margin-right: 0%;
        width: 25%;
        display: flex; }
        section article.info:last-child {
          margin-right: 0; } }
  section .button-wrapper {
    float: left;
    display: block;
    margin-right: 0%;
    width: 100%;
    text-align: center; }
    section .button-wrapper:last-child {
      margin-right: 0; }
    section .button-wrapper a {
      display: inline-block;
      padding: 1em;
      background-color: white;
      color: rgba(0, 0, 0, 0.8);
      border: 1px solid #292d35; }
      section .button-wrapper a:hover {
        animation: 200ms background-color ease-in;
        background-color: #292d35;
        color: #fafafa; }

@-webkit-keyframes background-color {
  from {
    background-color: white;
    color: rgba(0, 0, 0, 0.8); }
  to {
    background-color: #292d35;
    color: #fafafa; } }

@-moz-keyframes background-color {
  from {
    background-color: white;
    color: rgba(0, 0, 0, 0.8); }
  to {
    background-color: #292d35;
    color: #fafafa; } }

@keyframes background-color {
  from {
    background-color: white;
    color: rgba(0, 0, 0, 0.8); }
  to {
    background-color: #292d35;
    color: #fafafa; } }

footer {
  padding: 5em 0 0 0;
  text-align: center;
  background-color: #292d35;
  color: #fafafa;
  font-weight: 200;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }
  footer::after {
    clear: both;
    content: "";
    display: block; }
  footer section {
    display: block;
    padding-bottom: 25px;
    background-color: #292d35;
    color: #fafafa; }
    footer section::after {
      clear: both;
      content: "";
      display: block; }
    footer section address, footer section article {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%;
      margin: 0 0 1em 0;
      font-style: normal;
      padding: 0; }
      footer section address:last-child, footer section article:last-child {
        margin-right: 0; }
      @media screen and (max-width: 1199px) {
        footer section address.footer-logo, footer section article.footer-logo {
          display: none; } }
      footer section address.footer-logo h2, footer section article.footer-logo h2 {
        margin: 0; }
      @media screen and (min-width: 600px) and (max-width: 1199px) {
        footer section address, footer section article {
          float: left;
          display: block;
          margin-right: 0%;
          width: 50%;
          margin: 0 0 1em 0; }
          footer section address:last-child, footer section article:last-child {
            margin-right: 0; }
          footer section address.contact, footer section article.contact {
            float: left;
            display: block;
            margin-right: 0%;
            width: 50%; }
            footer section address.contact:last-child, footer section article.contact:last-child {
              margin-right: 0; } }
      @media screen and (min-width: 1200px) {
        footer section address, footer section article {
          float: left;
          display: block;
          margin-right: 0%;
          width: 33.3333333333%;
          margin: 0; }
          footer section address:last-child, footer section article:last-child {
            margin-right: 0; }
          footer section address.contact, footer section article.contact {
            float: left;
            display: block;
            margin-right: 0%;
            width: 33.3333333333%; }
            footer section address.contact:last-child, footer section article.contact:last-child {
              margin-right: 0; } }
      footer section address a, footer section article a {
        color: #c7c7c7; }
        footer section address a.social, footer section article a.social {
          display: inline-block;
          padding: 0.5em;
          margin: 0.5em; }
        footer section address a:hover, footer section article a:hover {
          color: white; }
      footer section address h2, footer section article h2 {
        font-weight: 300;
        font-size: 2em; }
      footer section address h3, footer section article h3 {
        font-weight: 200; }
  footer p {
    padding: 50px;
    margin: 0;
    letter-spacing: 1px; }

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }

dl {
  margin: 0; }

dt {
  font-weight: 600;
  margin: 0; }

dd {
  margin: 0; }

figure {
  margin: 0; }

img,
picture {
  margin: 0;
  max-width: 100%; }

table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%; }

th {
  font-weight: 600;
  padding: 0.75em 0;
  text-align: center; }

td {
  padding: 0.75em 0; }

tr,
td,
th {
  vertical-align: middle; }

@font-face {
  font-family: 'SilverThreadJF';
  src: url("../fonts/SilverThreadJF.otf");
  src: url("../fonts/SilverThreadJF.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

body {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Open Sans", "Helvetica", sans-serif;
  font-size: 1em;
  line-height: 1.5;
  background-color: white;
  font-smooth: anti-aliassing;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  @media screen and (max-width: 800px) {
    body {
      font-size: 0.9em; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", "Helvetica", sans-serif;
  font-size: 1.333em;
  line-height: 1.2;
  margin: 0 0 0.75em; }

p {
  margin: 0 0 0.75em; }

a {
  color: #292d35;
  text-decoration: none;
  transition: color 150ms ease; }
  a:active, a:focus, a:hover {
    color: #1f2228; }

hr {
  border-bottom: 1px solid #3f4552;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.5em 0; }

strong {
  font-weight: bold; }

/* --------------------------------

Main components

-------------------------------- */
@media screen and (min-width: 1200px) {
  body > nav {
    display: none; } }

.content, .header {
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  will-change: transform; }

.content, .header {
  position: relative;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s; }
  @media screen and (max-width: 1199px) {
    .content.nav-is-visible, .header.nav-is-visible {
      -webkit-transform: translateX(-260px);
      -moz-transform: translateX(-260px);
      -ms-transform: translateX(-260px);
      -o-transform: translateX(-260px);
      transform: translateX(-260px); } }

.content {
  background-color: white;
  min-height: calc(100vh - 50px);
  z-index: 2;
  margin-top: 80px; }
  @media screen and (min-width: 1200px) {
    .content {
      min-height: calc(100vh - 80px); } }
  @media screen and (max-width: 1199px) {
    .content {
      margin-top: 50px; } }

.header {
  height: 50px;
  background: white;
  z-index: 3;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14); }
  .header.body-is-scrolled {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 1px 10px 0 rgba(0, 0, 0, 0.16); }
  @media screen and (max-width: 1199px) {
    .header nav {
      display: none; } }
  .nav-is-fixed .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; }
  @media screen and (min-width: 1200px) {
    .header {
      height: 80px; }
      .header::after {
        clear: both;
        content: "";
        display: block; } }

.logo {
  position: absolute;
  left: 4%;
  top: 15%;
  font-size: 2em;
  font-weight: 300;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.6);
  font-family: "SilverThreadJF", "Open Sans", "Helvetica", sans-serif; }
  .logo img {
    display: block; }
  @media screen and (max-width: 1199px) {
    .logo {
      font-size: 1.6em; }
      .nav-on-left .logo {
        left: auto;
        right: 5%; } }
  @media screen and (min-width: 1200px) {
    .logo {
      top: 16px;
      left: 2em; } }

.header-buttons {
  position: absolute;
  display: inline-block;
  top: 3px;
  right: 5%; }
  .header-buttons li {
    display: inline-block; }
  @media screen and (max-width: 1199px) {
    .nav-on-left .header-buttons {
      right: auto;
      left: 5%; }
      .nav-on-left .header-buttons li {
        float: right; } }
  @media screen and (min-width: 1200px) {
    .header-buttons {
      top: 18px;
      right: 4em; } }

.nav-trigger {
  position: relative;
  display: block;
  width: 44px;
  height: 44px;
  overflow: hidden;
  white-space: nowrap;
  /* hide text */
  color: transparent;
  z-index: 3; }

.nav-trigger span, .nav-trigger span::before, .nav-trigger span::after {
  /* hamburger icon in CSS */
  position: absolute;
  display: inline-block;
  height: 3px;
  width: 24px;
  background: #292d35; }

.nav-trigger span {
  /* line in the center */
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -2px;
  -webkit-transition: background 0.3s 0.3s;
  -moz-transition: background 0.3s 0.3s;
  transition: background 0.3s 0.3s; }

.nav-trigger span::before, .nav-trigger span::after {
  /* other 2 lines */
  content: '';
  right: 0;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 0% 50%;
  -moz-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  -o-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-transition: -webkit-transform 0.3s 0.3s;
  -moz-transition: -moz-transform 0.3s 0.3s;
  transition: transform 0.3s 0.3s; }

.nav-trigger span::before {
  /* menu icon top line */
  top: -6px; }

.nav-trigger span::after {
  /* menu icon bottom line */
  top: 6px; }

.nav-trigger.nav-is-visible span {
  /* hide line in the center */
  background: transparent; }

.nav-trigger.nav-is-visible span::before, .nav-trigger.nav-is-visible span::after {
  /* keep visible other 2 lines */
  background: black; }

.nav-trigger.nav-is-visible span::before {
  -webkit-transform: translateX(4px) translateY(-3px) rotate(45deg);
  -moz-transform: translateX(4px) translateY(-3px) rotate(45deg);
  -ms-transform: translateX(4px) translateY(-3px) rotate(45deg);
  -o-transform: translateX(4px) translateY(-3px) rotate(45deg);
  transform: translateX(4px) translateY(-3px) rotate(45deg); }

.nav-trigger.nav-is-visible span::after {
  -webkit-transform: translateX(4px) translateY(2px) rotate(-45deg);
  -moz-transform: translateX(4px) translateY(2px) rotate(-45deg);
  -ms-transform: translateX(4px) translateY(2px) rotate(-45deg);
  -o-transform: translateX(4px) translateY(2px) rotate(-45deg);
  transform: translateX(4px) translateY(2px) rotate(-45deg); }

@media screen and (min-width: 1200px) {
  .nav-trigger {
    display: none; } }

.cd-primary-nav, .cd-primary-nav ul {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 260px;
  background: #292d35;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 1;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s; }
  .cd-primary-nav a, .cd-primary-nav ul a {
    display: block;
    line-height: 50px;
    padding: 0 20px;
    color: #fafafa;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: visible;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #343943;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    will-change: transform, opacity;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    -moz-transition: -moz-transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s; }
    .cd-primary-nav a p, .cd-primary-nav ul a p {
      white-space: pre-wrap; }
  .cd-primary-nav.is-hidden, .cd-primary-nav ul.is-hidden {
    /* secondary navigations hidden by default */
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%); }
  .cd-primary-nav.moves-out > li > a, .cd-primary-nav ul.moves-out > li > a {
    /* push the navigation items to the left - and lower down opacity - when secondary nav slides in */
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0; }

@media screen and (max-width: 1199px) {
  .nav-on-left .cd-primary-nav, .nav-on-left .cd-primary-nav ul {
    right: auto;
    left: 0; } }

.cd-primary-nav .cd-nav-gallery .cd-nav-item, .cd-primary-nav .cd-nav-icons .cd-nav-item {
  /* items with picture (or icon) and title */
  line-height: 80px; }
  .cd-primary-nav .cd-nav-gallery .cd-nav-item h3, .cd-primary-nav .cd-nav-icons .cd-nav-item h3 {
    font-size: 0.9em;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 80px;
    margin-left: 2em; }
    @media screen and (min-width: 1200px) {
      .cd-primary-nav .cd-nav-gallery .cd-nav-item h3, .cd-primary-nav .cd-nav-icons .cd-nav-item h3 {
        line-height: 1.2; } }

.cd-primary-nav .cd-nav-gallery .cd-nav-item {
  padding-left: 90px; }
  .cd-primary-nav .cd-nav-gallery .cd-nav-item img {
    position: absolute;
    display: block;
    height: 40px;
    width: auto;
    left: 20px;
    top: 50%;
    margin-top: -20px; }

.cd-primary-nav .cd-nav-icons .cd-nav-item p {
  color: #fafafa;
  font-size: 0.9em;
  /* hide description on small devices */
  display: none; }

@media screen and (max-width: 1199px) {
  .cd-primary-nav {
    /* by default .cd-primary-nav is hidden - trick for iOS devices where you can see the navigation if you pull down */
    visibility: hidden;
    -webkit-transition: visibility 0s 0.3s;
    -moz-transition: visibility 0s 0.3s;
    transition: visibility 0s 0.3s; }
    .cd-primary-nav.nav-is-visible {
      visibility: visible;
      -webkit-transition: visibility 0s 0s;
      -moz-transition: visibility 0s 0s;
      transition: visibility 0s 0s; } }

@media screen and (max-width: 1200px) {
  .cd-primary-nav .cd-nav-icons i {
    float: left;
    line-height: 80px; } }

@media screen and (min-width: 1200px) {
  .cd-primary-nav {
    position: static;
    padding: 0 5% 0 0;
    height: auto;
    width: auto;
    float: right;
    overflow: visible;
    background: transparent; }
    .cd-primary-nav::after {
      clear: both;
      content: "";
      display: block; }
    .cd-primary-nav.moves-out > li > a {
      /* reset mobile style */
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
      opacity: 1; }
    .cd-primary-nav ul {
      position: static;
      height: auto;
      width: auto;
      background: transparent;
      overflow: visible;
      z-index: 3; }
      .cd-primary-nav ul.is-hidden {
        /* reset mobile style */
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0); }
      .cd-primary-nav ul.moves-out > li > a {
        /* reset mobile style */
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1; }
    .cd-primary-nav > li {
      float: left;
      margin-left: 2.5em; }
    .cd-primary-nav > li > a {
      /* main navigation buttons style */
      position: relative;
      display: inline-block;
      height: 80px;
      line-height: 80px;
      padding: 0 10px;
      color: rgba(0, 0, 0, 0.8);
      overflow: visible;
      border-bottom: none;
      -webkit-transition: color 0.3s, box-shadow 0.3s;
      -moz-transition: color 0.3s, box-shadow 0.3s;
      transition: color 0.3s, box-shadow 0.3s; }
      .cd-primary-nav > li > a.selected, .cd-primary-nav > li > a.active {
        color: rgba(0, 0, 0, 0.8);
        box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.8); }
    .cd-primary-nav .go-back, .cd-primary-nav .see-all {
      display: none; }
    .cd-primary-nav .cd-secondary-nav, .cd-primary-nav .cd-nav-gallery, .cd-primary-nav .cd-nav-icons {
      /* dropdown menu style */
      position: absolute;
      top: 80px;
      width: 100vw;
      background: #292d35;
      padding: 48px 64px 80px;
      box-shadow: inset 0 1px 0 #292d35, 0 3px 6px rgba(0, 0, 0, 0.05);
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
      -webkit-transition: opacity .3s 0s, visibility 0s 0s;
      -moz-transition: opacity .3s 0s, visibility 0s 0s;
      transition: opacity .3s 0s, visibility 0s 0s; }
      .cd-primary-nav .cd-secondary-nav::after, .cd-primary-nav .cd-nav-gallery::after, .cd-primary-nav .cd-nav-icons::after {
        clear: both;
        content: "";
        display: block; }
      .cd-primary-nav .cd-secondary-nav.is-hidden, .cd-primary-nav .cd-nav-gallery.is-hidden, .cd-primary-nav .cd-nav-icons.is-hidden {
        opacity: 0;
        visibility: hidden;
        -webkit-transition: opacity .3s 0s, visibility 0s .3s;
        -moz-transition: opacity .3s 0s, visibility 0s .3s;
        transition: opacity .3s 0s, visibility 0s .3s; }
      .cd-primary-nav .cd-secondary-nav > .see-all, .cd-primary-nav .cd-nav-gallery > .see-all, .cd-primary-nav .cd-nav-icons > .see-all {
        /* this is the BIG See all button at the bottom of the dropdown menu */
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 80px;
        width: 100%;
        overflow: hidden;
        font-weight: normal;
        background-color: #f2f2f2;
        /* reset some inherited style */
        margin: 0;
        padding: 0; }
        .cd-primary-nav .cd-secondary-nav > .see-all a, .cd-primary-nav .cd-nav-gallery > .see-all a, .cd-primary-nav .cd-nav-icons > .see-all a {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          font-size: 1.8em;
          font-weight: normal;
          text-align: center;
          line-height: 80px;
          border-top: 1px solid #1e2127;
          /* reset some inherited style */
          border-bottom: none;
          margin: 0;
          padding: 0;
          color: #292d35;
          -webkit-transition: color 0.2s, background 0.2s, border 0.2s;
          -moz-transition: color 0.2s, background 0.2s, border 0.2s;
          transition: color 0.2s, background 0.2s, border 0.2s; }
          .cd-primary-nav .cd-secondary-nav > .see-all a:hover, .cd-primary-nav .cd-nav-gallery > .see-all a:hover, .cd-primary-nav .cd-nav-icons > .see-all a:hover {
            background: #1e2127;
            border-color: #292d35;
            color: #fafafa; }
    .cd-primary-nav .cd-secondary-nav > li {
      /* change the height according to your needs - you can even set height: auto */
      height: 340px;
      width: 22%;
      float: left;
      margin-right: 2.66%;
      border-right: 1px solid #292d35;
      overflow: hidden;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch; }
      .cd-primary-nav .cd-secondary-nav > li:nth-child(4n+2) {
        /* +2 because we have 2 list items with display:none */
        margin-right: 0;
        border-right: none; }
      .cd-primary-nav .cd-secondary-nav > li > a {
        /* secondary nav title */
        color: #fafafa;
        font-weight: bold;
        font-size: 1.6rem;
        margin-bottom: .6em; }
    .cd-primary-nav .cd-secondary-nav a {
      height: 30px;
      line-height: 30px;
      padding: 0 18% 0 0;
      color: #292d35;
      border-bottom: none;
      font-size: 1.4rem; }
    .cd-primary-nav .cd-secondary-nav ul {
      /* Force Hardware Acceleration in WebKit */
      -webkit-transform: translateZ(0);
      -moz-transform: translateZ(0);
      -ms-transform: translateZ(0);
      -o-transform: translateZ(0);
      transform: translateZ(0); }
    .cd-primary-nav .cd-secondary-nav ul ul {
      /* tertiary navigation */
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%; }
      .cd-primary-nav .cd-secondary-nav ul ul.is-hidden {
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%); }
      .cd-primary-nav .cd-secondary-nav ul ul .go-back {
        display: block; }
        .cd-primary-nav .cd-secondary-nav ul ul .go-back a {
          color: transparent; }
      .cd-primary-nav .cd-secondary-nav ul ul .see-all {
        display: block; }
    .cd-primary-nav .cd-secondary-nav .moves-out > li > a {
      /* push the navigation items to the left - and lower down opacity - when tertiary nav slides in */
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      transform: translateX(-100%); }
    .cd-primary-nav .cd-nav-gallery li {
      width: 22%;
      float: left;
      margin: 0 4% 40px 0; }
      .cd-primary-nav .cd-nav-gallery li:nth-child(4n+2) {
        /* +2 because we have two additional list items with display:none */
        margin-right: 0; }
    .cd-primary-nav .cd-nav-gallery .cd-nav-item {
      border-bottom: none;
      padding: 0;
      height: auto;
      line-height: 1.2; }
      .cd-primary-nav .cd-nav-gallery .cd-nav-item img {
        position: static;
        margin-top: 0;
        height: auto;
        width: 100%;
        margin-bottom: .6em; }
      .cd-primary-nav .cd-nav-gallery .cd-nav-item h3 {
        color: #fafafa;
        font-weight: normal;
        padding: 0;
        margin: 0; }
    .cd-primary-nav .cd-nav-icons li {
      width: 23%;
      float: left;
      margin: 0 2% 20px 0; }
      .cd-primary-nav .cd-nav-icons li i {
        float: left;
        line-height: 3em; }
      .cd-primary-nav .cd-nav-icons li:nth-child(3n+2) {
        /* +2 because we have two additional list items with display:none */
        margin-right: 0; }
    .cd-primary-nav .cd-nav-icons .cd-nav-item {
      border-bottom: none;
      line-height: 1.2;
      padding: 24px 0 10px 10px;
      position: relative; }
      .cd-primary-nav .cd-nav-icons .cd-nav-item:hover {
        background: #1e2127; }
      .cd-primary-nav .cd-nav-icons .cd-nav-item h3 {
        margin-left: 3em;
        font-size: 1em;
        color: #fafafa;
        font-weight: bold; }
      .cd-primary-nav .cd-nav-icons .cd-nav-item p {
        margin-left: 3.32em;
        display: block; }
      .cd-primary-nav .cd-nav-icons .cd-nav-item::before {
        left: 25px; } }

.has-children > a, .go-back a {
  position: relative; }
  .has-children > a::before, .has-children > a::after, .go-back a::before, .go-back a::after {
    /* arrow icon in CSS - for element with nested unordered lists */
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -1px;
    display: inline-block;
    height: 2px;
    width: 10px;
    background: #3f4552;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }
  .has-children > a::before, .go-back a::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg); }
  .has-children > a::after, .go-back a::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  @media screen and (min-width: 1200px) {
    .has-children > a::before, .has-children > a::after, .go-back a::before, .go-back a::after {
      background: #131518; }
    .has-children > a:hover::before, .has-children > a:hover::after, .go-back a:hover::before, .go-back a:hover::after {
      background: #fafafa; } }

.has-children > a {
  padding-right: 40px; }
  .has-children > a::before, .has-children > a::after {
    /* arrow goes on the right side - children navigation */
    right: 20px;
    -webkit-transform-origin: 9px 50%;
    -moz-transform-origin: 9px 50%;
    -ms-transform-origin: 9px 50%;
    -o-transform-origin: 9px 50%;
    transform-origin: 9px 50%; }

.cd-primary-nav .go-back a {
  padding-left: 40px; }
  .cd-primary-nav .go-back a::before, .cd-primary-nav .go-back a::after {
    /* arrow goes on the left side - go back button */
    left: 20px;
    -webkit-transform-origin: 1px 50%;
    -moz-transform-origin: 1px 50%;
    -ms-transform-origin: 1px 50%;
    -o-transform-origin: 1px 50%;
    transform-origin: 1px 50%; }

@media screen and (min-width: 1200px) {
  .has-children > a::before, .has-children > a::after {
    right: 15%; }
  .cd-primary-nav > .has-children > a {
    /* main navigation arrows on larger devices */
    padding-right: 30px !important; }
    .cd-primary-nav > .has-children > a::before, .cd-primary-nav > .has-children > a::after {
      width: 9px;
      -webkit-transform-origin: 50% 50%;
      -moz-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
      -o-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      background: #131518;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transition: width 0.3s, -webkit-transform 0.3s;
      -moz-transition: width 0.3s, -moz-transform 0.3s;
      transition: width 0.3s, transform 0.3s; }
    .cd-primary-nav > .has-children > a::before {
      right: 12px; }
    .cd-primary-nav > .has-children > a::after {
      right: 7px; }
    .cd-primary-nav > .has-children > a.selected::before, .cd-primary-nav > .has-children > a.selected::after {
      width: 14px; }
    .cd-primary-nav > .has-children > a.selected::before {
      -webkit-transform: translateX(5px) rotate(-45deg);
      -moz-transform: translateX(5px) rotate(-45deg);
      -ms-transform: translateX(5px) rotate(-45deg);
      -o-transform: translateX(5px) rotate(-45deg);
      transform: translateX(5px) rotate(-45deg); }
    .cd-primary-nav > .has-children > a.selected::after {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg); }
  .cd-secondary-nav > .has-children > a::before, .cd-secondary-nav > .has-children > a::after {
    /* remove arrows on secondary nav titles */
    display: none; }
  .cd-primary-nav .go-back a {
    padding-left: 20px; }
    .cd-primary-nav .go-back a::before, .cd-primary-nav .go-back a::after {
      left: 1px; } }

.cd-primary-nav > .language-switcher {
  color: #fafafa; }
  @media screen and (max-width: 1199px) {
    .cd-primary-nav > .language-switcher {
      position: fixed;
      width: 100%;
      bottom: 0; }
      .cd-primary-nav > .language-switcher > a:first-child {
        border-top: 1px solid #343943; } }
  @media screen and (max-width: 1199px) {
    .cd-primary-nav > .language-switcher span {
      display: none; } }
  .cd-primary-nav > .language-switcher > a {
    padding: 0; }
    @media screen and (max-width: 1199px) {
      .cd-primary-nav > .language-switcher > a {
        padding: 0 20px; } }
    .cd-primary-nav > .language-switcher > a.selected {
      box-shadow: none;
      font-weight: bold; }

.overlay {
  /* shadow layer visible when navigation is active */
  position: fixed;
  z-index: 2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  background-color: rgba(41, 45, 53, 0.8);
  visibility: hidden;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s, -webkit-transform 0.3s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s, -moz-transform 0.3s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s, transform 0.3s 0s; }
  .overlay.is-visible {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0s, -webkit-transform 0.3s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0s, -moz-transform 0.3s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0s, transform 0.3s 0s; }
  @media screen and (max-width: 1199px) {
    .overlay.is-visible {
      -webkit-transform: translateX(-260px);
      -moz-transform: translateX(-260px);
      -ms-transform: translateX(-260px);
      -o-transform: translateX(-260px);
      transform: translateX(-260px); }
    .nav-on-left .overlay.is-visible {
      -webkit-transform: translateX(260px);
      -moz-transform: translateX(260px);
      -ms-transform: translateX(260px);
      -o-transform: translateX(260px);
      transform: translateX(260px); }
    .overlay.is-visible.search-is-visible, .nav-on-left .overlay.is-visible.search-is-visible {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0); } }

/* --------------------------------

support for no js

-------------------------------- */
.no-js .cd-primary-nav {
  position: relative;
  height: auto;
  width: 100%;
  overflow: visible;
  visibility: visible;
  z-index: 2; }

.no-js {
  position: relative;
  top: 0;
  opacity: 1;
  visibility: visible; }

@media screen and (min-width: 1200px) {
  .no-js .cd-primary-nav {
    position: absolute;
    z-index: 3;
    display: inline-block;
    width: auto;
    top: 0;
    right: 150px;
    padding: 0; }
  .no-js .nav-is-fixed .cd-primary-nav {
    position: fixed; } }

.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-theme .owl-nav [class*='owl-'] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px; }

.owl-theme .owl-nav [class*='owl-']:hover {
  background: #4DC7A0;
  color: #FFF;
  text-decoration: none; }

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }

.owl-carousel .owl-dot {
  display: inline-block;
  zoom: 1; }

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px; }

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #292d35; }

.owl-theme .owl-nav [class*='owl-'] {
  background-color: white;
  color: rgba(0, 0, 0, 0.8); }
  .owl-theme .owl-nav [class*='owl-']:hover {
    background-color: #292d35;
    color: #fafafa; }

.owl-carousel .owl-item {
  transform: translateZ(-100px); }
  .owl-carousel .owl-item .carousel img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto; }

.carousel-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center; }
  .carousel-content .text {
    text-align: center; }

.loading-placeholder {
  width: 100vw;
  height: 34vw;
  display: flex;
  justify-content: center;
  align-items: center; }

.carousel-overlay {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); }

@-webkit-keyframes loader {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes loader {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes loader {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.loader {
  text-align: center;
  margin-bottom: 1em; }
  .loader .dot {
    border-radius: 50%;
    width: 1em;
    height: 1em;
    background-color: #292d35;
    display: inline-block;
    animation: loader;
    animation-duration: 1.4s;
    animation-iteration-count: infinite; }
    .loader .dot:nth-child(2) {
      animation-delay: 0.2s; }
    .loader .dot:nth-child(3) {
      animation-delay: 0.4s; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-moz-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeOut {
  -webkit-animation: fadeOut;
  -moz-animation: fadeOut;
  animation: fadeOut; }

.animated {
  -webkit-animation-duration: 1ms;
  animation-duration: 1ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

section.item {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 2em; }
  section.item::after {
    clear: both;
    content: "";
    display: block; }
  section.item h1 {
    text-transform: uppercase; }
  section.item .carousel-wrapper {
    float: left;
    display: block;
    width: 100%;
    text-align: left; }
    @media screen and (min-width: 1200px) {
      section.item .carousel-wrapper {
        width: 50%; } }
    section.item .carousel-wrapper .owl-carousel .owl-nav {
      margin: 0; }
    section.item .carousel-wrapper .owl-carousel .owl-dots {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0; }
    section.item .carousel-wrapper .owl-carousel .owl-prev {
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      background-color: transparent;
      border: none;
      margin: 0;
      padding: 2em; }
      section.item .carousel-wrapper .owl-carousel .owl-prev span {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 1em;
        display: flex;
        align-items: center;
        justify-content: center; }
      section.item .carousel-wrapper .owl-carousel .owl-prev i {
        font-size: 2em; }
    section.item .carousel-wrapper .owl-carousel .owl-next {
      position: absolute;
      bottom: 0;
      top: 0;
      right: 0;
      background-color: transparent;
      border: none;
      margin: 0;
      padding: 2em; }
      section.item .carousel-wrapper .owl-carousel .owl-next span {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 1em;
        display: flex;
        align-items: center;
        justify-content: center; }
      section.item .carousel-wrapper .owl-carousel .owl-next i {
        font-size: 2em; }
    section.item .carousel-wrapper h1 {
      padding: 0.5em;
      font-size: 1.5em; }
  section.item .thumbnails {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;
    max-width: 50%; }
    section.item .thumbnails .thumbnail {
      display: flex;
      align-items: center;
      margin: 0.2em;
      background-color: #eee;
      width: 5em;
      cursor: pointer; }
      section.item .thumbnails .thumbnail img {
        display: block; }
  section.item article {
    float: left;
    display: block;
    padding: 3em 0;
    width: 100%;
    font-weight: 300;
    font-size: 1.2em; }
    @media screen and (min-width: 1200px) {
      section.item article {
        width: 50%;
        padding: 0 3em; } }
    section.item article .description {
      text-align: justify;
      margin-bottom: 5em; }
  section.item .contact {
    float: left;
    display: block;
    width: 100%;
    margin-top: 5em;
    position: relative;
    padding: 2em; }
    @media screen and (min-width: 1200px) {
      section.item .contact {
        width: 80%;
        margin-left: 10%; } }
    section.item .contact #form-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      justify-content: center;
      align-items: center;
      background-color: white;
      display: none; }
      section.item .contact #form-overlay.show {
        display: flex; }
    section.item .contact h3 {
      font-weight: 400; }
    section.item .contact .errors {
      color: #D50000; }
      section.item .contact .errors h4 {
        font-weight: normal;
        font-size: 0.9em;
        padding: 0 0 1em 0;
        margin: 0; }
      section.item .contact .errors .errors {
        color: #D50000; }
    section.item .contact form.transparent * {
      color: white; }
      section.item .contact form.transparent *::placeholder {
        color: white; }

.filter-bar-mobile {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f2f2f2;
  margin: 0.5em 0 1em 0; }
  .filter-bar-mobile a {
    display: block;
    padding: 1em;
    flex-grow: 1;
    text-align: center; }
    .filter-bar-mobile a:hover {
      background-color: #292d35;
      color: white; }
  @media screen and (min-width: 1200px) {
    .filter-bar-mobile {
      display: none; } }

.filterbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-bottom: 1em;
  padding: 0 1em;
  text-transform: uppercase;
  flex-wrap: wrap; }
  @media screen and (max-width: 1200px) {
    .filterbar {
      display: none; } }
  .filterbar a, .filterbar button, .filterbar select {
    font-weight: 400;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.8);
    margin: 0 0.5em; }
    .filterbar a:focus, .filterbar a:active, .filterbar button:focus, .filterbar button:active, .filterbar select:focus, .filterbar select:active {
      border: none;
      box-shadow: none;
      background-color: #292d35;
      color: #fafafa; }
  .filterbar span {
    margin: 0 1em 0 0; }
  .filterbar form {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .filterbar form input, .filterbar form select, .filterbar form label {
      margin: 0;
      border: none; }
    .filterbar form .sort {
      display: flex;
      flex-direction: row; }

.search-box {
  margin: 0 1em; }
  .search-box input {
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #292d35; }
    .search-box input:focus, .search-box input:active {
      border: none;
      border-bottom: 1px solid #292d35;
      box-shadow: none;
      background-color: transparent; }

.sort-box {
  margin: 0 1em 1em 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }
  .sort-box .smaller {
    font-size: 0.8em; }
  .sort-box a, .sort-box button, .sort-box select {
    font-weight: 400;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.8);
    margin: 0 0.5em; }

.sub-categories-bar {
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }
  .sub-categories-bar a.btn {
    margin: 0.5em;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.8); }
    .sub-categories-bar a.btn.active {
      background-color: #292d35;
      color: #fafafa; }

section.info {
  text-align: center; }
  section.info #map {
    height: 50vh; }
  section.info .banner {
    height: 50vh;
    background-size: cover;
    background-position: center;
    overflow: hidden; }
    section.info .banner.shipping {
      background-image: url("../img/shipping.jpg"); }
    section.info .banner.warehouse {
      background-image: url("../img/warehouse1.jpg");
      background-position: top;
      height: 50vh; }
    section.info .banner.general {
      background-image: url("../img/general.jpg"); }
  section.info h2 {
    font-size: 1.5em;
    font-weight: 300;
    padding: 2em; }
  section.info .wrapper {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto; }
    section.info .wrapper::after {
      clear: both;
      content: "";
      display: block; }
    @media screen and (max-width: 1200px) {
      section.info .wrapper {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto; }
        section.info .wrapper::after {
          clear: both;
          content: "";
          display: block; } }
    section.info .wrapper .wrapper-row {
      display: block; }
      section.info .wrapper .wrapper-row::after {
        clear: both;
        content: "";
        display: block; }
      section.info .wrapper .wrapper-row .address-wrapper {
        float: left;
        display: block;
        margin-right: 0%;
        width: 50%;
        text-align: left; }
        section.info .wrapper .wrapper-row .address-wrapper:last-child {
          margin-right: 0; }
        @media screen and (max-width: 1200px) {
          section.info .wrapper .wrapper-row .address-wrapper {
            float: left;
            display: block;
            margin-right: 0%;
            width: 100%;
            padding: 1em; }
            section.info .wrapper .wrapper-row .address-wrapper:last-child {
              margin-right: 0; } }
        section.info .wrapper .wrapper-row .address-wrapper h2 {
          text-align: left;
          padding: 0 0 1.5em 0;
          margin: 0; }
          @media screen and (max-width: 1200px) {
            section.info .wrapper .wrapper-row .address-wrapper h2 {
              padding: 0 0 1em 0; } }
        section.info .wrapper .wrapper-row .address-wrapper p.subtitle {
          font-size: 1.2em;
          padding: 0 0 1em 0;
          margin: 0;
          text-align: left; }
        section.info .wrapper .wrapper-row .address-wrapper address {
          font-style: normal;
          text-align: left; }
      section.info .wrapper .wrapper-row .contact {
        float: left;
        display: block;
        margin-right: 0%;
        width: 50%;
        position: relative;
        text-align: left; }
        section.info .wrapper .wrapper-row .contact:last-child {
          margin-right: 0; }
        @media screen and (max-width: 1200px) {
          section.info .wrapper .wrapper-row .contact {
            float: left;
            display: block;
            margin-right: 0%;
            width: 100%;
            padding: 1em; }
            section.info .wrapper .wrapper-row .contact:last-child {
              margin-right: 0; } }
        section.info .wrapper .wrapper-row .contact #form-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          justify-content: center;
          align-items: center;
          background-color: white;
          display: none; }
          section.info .wrapper .wrapper-row .contact #form-overlay.show {
            display: flex; }
        section.info .wrapper .wrapper-row .contact h2 {
          padding: 0 0 1.5em 0;
          margin: 0; }
          @media screen and (max-width: 1200px) {
            section.info .wrapper .wrapper-row .contact h2 {
              padding: 0 0 1em 0; } }
        section.info .wrapper .wrapper-row .contact h4 {
          font-weight: normal;
          font-size: 0.9em;
          padding: 0 0 1em 0;
          margin: 0; }
        section.info .wrapper .wrapper-row .contact .errors {
          color: #D50000; }
        section.info .wrapper .wrapper-row .contact form.transparant * {
          color: white; }
          section.info .wrapper .wrapper-row .contact form.transparant *::placeholder {
            color: white; }
  section.info p {
    width: 100%;
    padding: 0 2em;
    text-align: justify; }
    section.info p a {
      color: rgba(77, 77, 77, 0.8); }
      section.info p a:hover {
        color: rgba(26, 26, 26, 0.8); }
    @media screen and (min-width: 1200px) {
      section.info p {
        width: 60%;
        margin-left: 20%; } }

@-webkit-keyframes slide-in-right {
  from {
    transform: translateX(30vw); }
  to {
    transform: translateX(0); } }

@-moz-keyframes slide-in-right {
  from {
    transform: translateX(30vw); }
  to {
    transform: translateX(0); } }

@keyframes slide-in-right {
  from {
    transform: translateX(30vw); }
  to {
    transform: translateX(0); } }

@-webkit-keyframes slide-out-right {
  from {
    transform: translateX(0vw); }
  to {
    transform: translateX(35vw); } }

@-moz-keyframes slide-out-right {
  from {
    transform: translateX(0vw); }
  to {
    transform: translateX(35vw); } }

@keyframes slide-out-right {
  from {
    transform: translateX(0vw); }
  to {
    transform: translateX(35vw); } }

.toast {
  position: fixed;
  right: 1em;
  left: 70%;
  top: calc(1em + 50px);
  text-align: center;
  z-index: 300;
  padding: 1em;
  font-size: 0.9em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  animation: 400ms slide-in-right linear; }
  @media screen and (min-width: 1200px) {
    .toast {
      top: calc(1em + 80px); } }
  .toast p {
    margin: 0;
    padding: 0; }
  .toast.success {
    background-color: #0e753b;
    color: white; }
  .toast.out {
    animation: 400ms slide-out-right linear; }
