section.info {
  text-align: center;
  #map {
    height: 50vh;
  }
  .banner {
    height: 50vh;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    &.shipping {
      background-image: url("../img/shipping.jpg");
    }
    &.warehouse {
      background-image: url("../img/warehouse1.jpg");
      background-position: top;
      height: 50vh;
    }
    &.general {
      background-image: url("../img/general.jpg");
    }
    #map {
    }
  }
  h2 {
    font-size: 1.5em;
    font-weight: 300;
    padding: 2em;
  }
  .wrapper{
    @include outer-container(80%);
    @include media(max-width $large-screen) {
      @include outer-container(100%);
    }
    .wrapper-row {
      @include row();
      .address-wrapper {
        @include span-columns(6);
        text-align: left;
        @include media(max-width $large-screen) {
          @include span-columns(12);
          padding: 1em;
        }
        h2 {
          text-align: left;
          padding: 0 0 1.5em 0;
          margin: 0;
          @include media(max-width $large-screen) {
            padding: 0 0 1em 0;
          }
        }
        p.subtitle {
          font-size: 1.2em;
          padding: 0 0 1em 0;
          margin: 0;
          text-align: left;
        }
        address {
          font-style: normal;
          text-align: left;
        }
      }
      .contact {
        @include span-columns(6);
        @include media(max-width $large-screen) {
          @include span-columns(12);
          padding: 1em;
        }
        position: relative;
        #form-overlay {
          position: absolute;
          top: 0;
          left:0;
          right: 0;
          bottom: 0;
          justify-content: center;
          align-items: center;
          background-color: $base-background-color;
          display: none;
          &.show {
            display: flex;
          }
        }
        text-align: left;
        h2 {
          padding: 0 0 1.5em 0;
          margin: 0;
          @include media(max-width $large-screen) {
            padding: 0 0 1em 0;
          }
        }
        h4 {
          font-weight: normal;
          font-size: 0.9em;
          padding: 0 0 1em 0;
          margin: 0;
        }
        .errors {
          color: $error-color;
        }
        form.transparant {
          * {
            &::placeholder {
              color: $base-background-color;
            }
            color: $base-background-color;
          }
        }
      }
    }
  }
  p {
    width: 100%;
    padding: 0 2em;
    text-align: justify;

    a {
      color: lighten($base-font-color, 30%);

      &:hover {
        color: lighten($base-font-color, 10%);
      }
    }

    @include media(min-width $large-screen) {
      width: 60%;
      margin-left: 20%;
    }
  }
}