@include keyframes(loader) {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loader {
  text-align: center;
  margin-bottom: 1em;
  .dot {
    border-radius: 50%;
    width: 1em;
    height: 1em;
    background-color: $secondary-background-color;
    display: inline-block;
    animation: loader;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}