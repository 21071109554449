/* --------------------------------

Main components

-------------------------------- */
body {
  > nav {
    @include media(min-width $large-screen) {
      display: none;
    }
  }
}

.content, .header {
  /* Force Hardware Acceleration in WebKit */
  @include transform(translateZ(0));
  will-change: transform;
}

.content, .header {
  position: relative;
  @include transition(transform .3s);

  @include media(max-width $large-screen - 1px) {
    &.nav-is-visible {
      @include transform(translateX(-$navbar-width));
    }
  }
}

.content {
  background-color: $base-background-color;
  min-height: calc(100vh - #{$navbar-height-s});
  @include media(min-width $large-screen) {
    min-height: calc(100vh - #{$navbar-height-l});
  }
  z-index: $content;
  margin-top: $navbar-height-l;
  @include media(max-width $large-screen - 1px) {
    margin-top: $navbar-height-s;
  }
}

.header {
  height: $navbar-height-s;
  background: $base-background-color;
  z-index: $above-content;
  border-bottom: 1px solid rgba(0,0,0,0.14);

  &.body-is-scrolled {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.12),0 4px 5px 0 rgba(0,0,0,0.12),0 1px 10px 0 rgba(0,0,0,0.16)
  }

  nav {
    @include media(max-width $large-screen - 1px) {
      display: none;
    }
  }
  .nav-is-fixed & {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  @include media(min-width $large-screen) {
    height: $navbar-height-l;
    @include clearfix;
  }
}

.logo {
  position: absolute;
  left: 4%;
  top: 15%;
  font-size: 2em;
  font-weight: 300;
  letter-spacing: 2px;
  color: rgba(0,0,0,0.6);
  font-family: $logo-font-family;
  img {
    display: block;
  }

  @include media(max-width $large-screen - 1px) {
    .nav-on-left & {
      left: auto;
      right: 5%;
    }
    font-size: 1.6em;
  }

  @include media(min-width $large-screen) {
    top: 16px;
    left: 2em;
  }
}

.header-buttons {
  position: absolute;
  display: inline-block;
  top: 3px;
  right: 5%;

  li {
    display: inline-block;
  }

  @include media(max-width $large-screen - 1px) {
    .nav-on-left & {
      right: auto;
      left: 5%;

      li {
        float: right;
      }
    }
  }

  @include media(min-width $large-screen) {
    top: 18px;
    right: 4em;
  }
}

.nav-trigger {
  position: relative;
  display: block;
  width: 44px;
  height: 44px;
  overflow: hidden;
  white-space: nowrap;
  /* hide text */
  color: transparent;
  z-index: $above-content;
}

.nav-trigger {

  span, span::before, span::after {
    /* hamburger icon in CSS */
    position: absolute;
    display: inline-block;
    height: 3px;
    width: 24px;
    background: $secondary-background-color;
  }

  span {
    /* line in the center */
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -2px;
    @include transition(background .3s .3s);
  }

  span::before, span::after {
    /* other 2 lines */
    content: '';
    right: 0;
    /* Force Hardware Acceleration in WebKit */
    @include transform(translateZ(0));
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    @include transform-origin(0% 50%);
    @include transition(transform .3s .3s);
  }

  span::before {
    /* menu icon top line */
    top: -6px;
  }

  span::after {
    /* menu icon bottom line */
    top: 6px;
  }

  &.nav-is-visible {
    span {
      /* hide line in the center */
      background: rgba($base-font-color, 0);
    }

    span::before, span::after {
      /* keep visible other 2 lines */
      background: rgba($base-font-color, 1);
    }

    span::before {
      @include transform(translateX(4px) translateY(-3px) rotate(45deg));
    }

    span::after {
      @include transform(translateX(4px) translateY(2px) rotate(-45deg));
    }
  }

  @include media(min-width $large-screen) {
    display: none;
  }
}

.cd-primary-nav, .cd-primary-nav ul {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: $navbar-width;
  background: $secondary-background-color;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: $below-content;
  /* Force Hardware Acceleration in WebKit */
  @include transform(translateZ(0));
  @include transform(translateX(0));
  @include transition(transform .3s);

  a {
    display: block;
    line-height: 50px;
    padding: 0 20px;
    color: $secondary-font-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: visible;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid lighten($secondary-background-color, 5%);
    @include transform(translateZ(0));
    will-change: transform, opacity;
    @include transition(transform .3s, opacity .3s);

    p {
      white-space: pre-wrap;
    }
  }

  &.is-hidden {
    /* secondary navigations hidden by default */
    @include transform(translateX(100%));
  }

  &.moves-out > li > a {
    /* push the navigation items to the left - and lower down opacity - when secondary nav slides in */
    @include transform(translateX(-100%));
    opacity: 0;
  }
}

@include media(max-width $large-screen - 1px) {
  .nav-on-left .cd-primary-nav, .nav-on-left .cd-primary-nav ul {
    right: auto;
    left: 0;
  }
}

.cd-primary-nav {

  .cd-nav-gallery .cd-nav-item, .cd-nav-icons .cd-nav-item {
    /* items with picture (or icon) and title */
    line-height: 80px;

    h3 {
      font-size: 0.9em;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 80px;
      margin-left: 2em;

      @include media(min-width $large-screen) {
        line-height: 1.2;
      }
    }

  }

  .cd-nav-gallery .cd-nav-item {
    padding-left: 90px;

    img {
      position: absolute;
      display: block;
      height: 40px;
      width: auto;
      left: 20px;
      top: 50%;
      margin-top: -20px;
    }
  }

  .cd-nav-icons .cd-nav-item {

    p {
      color: $secondary-font-color;
      font-size: 0.9em;
      /* hide description on small devices */
      display: none;
    }

  }

  @include media(max-width $large-screen - 1px) {
    /* by default .cd-primary-nav is hidden - trick for iOS devices where you can see the navigation if you pull down */
    visibility: hidden;
    @include transition(visibility 0s .3s);

    &.nav-is-visible {
      visibility: visible;
      @include transition(visibility 0s 0s);
    }
  }

  .cd-nav-icons {
    @include media(max-width $large-screen -1) {
      i {
        float:left;
        line-height: 80px;
      }
    }
  }
}

@include media(min-width $large-screen) {

  .cd-primary-nav {
    position: static;
    padding: 0 5% 0 0;
    height: auto;
    width: auto;
    float: right;
    overflow: visible;
    background: transparent;
    @include clearfix;

    &.moves-out > li > a {
      /* reset mobile style */
      @include transform(translateX(0));
      opacity: 1;
    }

    ul {
      position: static;
      height: auto;
      width: auto;
      background: transparent;
      overflow: visible;
      z-index: $above-content;

      &.is-hidden {
        /* reset mobile style */
        @include transform(translateX(0));
      }

      &.moves-out > li > a {
        /* reset mobile style */
        @include transform(translateX(0));
        opacity: 1;
      }
    }

    > li {
      float: left;
      margin-left: 2.5em;
    }

    > li > a {
      /* main navigation buttons style */
      position: relative;
      display: inline-block;
      height: $navbar-height-l;
      line-height: $navbar-height-l;
      padding: 0 10px;
      color: $base-font-color;
      overflow: visible;
      border-bottom: none;
      @include transition(color .3s, box-shadow .3s);

      &.selected, &.active {
        color: $base-font-color;
        box-shadow: inset 0 -2px 0 $base-font-color;
      }
    }

    .go-back, .see-all {
      display: none;
    }

    .cd-secondary-nav, .cd-nav-gallery, .cd-nav-icons {
      /* dropdown menu style */
      position: absolute;
      top: $navbar-height-l;
      width: 100vw;
      background: $secondary-background-color;
      padding: 48px 64px 80px;
      box-shadow: inset 0 1px 0 $secondary-background-color, 0 3px 6px rgba(#000, .05);
      @include transform(translateX(0));
      @include clearfix;
      -webkit-transition: opacity .3s 0s, visibility 0s 0s;
      -moz-transition: opacity .3s 0s, visibility 0s 0s;
      transition: opacity .3s 0s, visibility 0s 0s;

      &.is-hidden {
        opacity: 0;
        visibility: hidden;
        -webkit-transition: opacity .3s 0s, visibility 0s .3s;
        -moz-transition: opacity .3s 0s, visibility 0s .3s;
        transition: opacity .3s 0s, visibility 0s .3s;
      }

      > .see-all {
        /* this is the BIG See all button at the bottom of the dropdown menu */
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 80px;
        width: 100%;
        overflow: hidden;
        font-weight: normal;
        background-color: darken($base-background-color, 5%);
        /* reset some inherited style */
        margin: 0;
        padding: 0;

        a {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          font-size: 1.8em;
          font-weight: normal;
          text-align: center;
          line-height: 80px;
          border-top: 1px solid darken($secondary-background-color, 5%);
          /* reset some inherited style */
          border-bottom: none;
          margin: 0;
          padding: 0;
          color: $secondary-background-color;
          @include transition(color .2s, background .2s, border .2s);

          &:hover {
            background: darken($secondary-background-color, 5%);
            border-color: $secondary-background-color;
            color: $secondary-font-color;
          }
        }
      }
    }

    .cd-secondary-nav {

      > li {
        /* change the height according to your needs - you can even set height: auto */
        height: 340px;

        width: 22%;
        float: left;
        margin-right: 2.66%;
        border-right: 1px solid $secondary-background-color;
        overflow: hidden;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        &:nth-child(4n+2) {
          /* +2 because we have 2 list items with display:none */
          margin-right: 0;
          border-right: none;
        }

        > a {
          /* secondary nav title */
          color: $secondary-font-color;
          font-weight: bold;
          font-size: 1.6rem;
          margin-bottom: .6em;
        }
      }

      a {
        height: 30px;
        line-height: 30px;
        padding: 0 18% 0 0;
        color: $secondary-background-color;
        border-bottom: none;
        font-size: 1.4rem;
      }

      ul {
        /* Force Hardware Acceleration in WebKit */
        @include transform(translateZ(0));
      }

      ul ul {
        /* tertiary navigation */
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        &.is-hidden {
          @include transform(translateX(100%));
        }

        .go-back {
          display: block;

          a {
            color: transparent;
          }
        }

        .see-all {
          display: block;
        }
      }

      .moves-out > li > a {
        /* push the navigation items to the left - and lower down opacity - when tertiary nav slides in */
        @include transform(translateX(-100%));
      }
    }

    .cd-nav-gallery {
      li {
        width: 100% * 0.22;
        float: left;
        margin: 0 4% 40px 0;

        &:nth-child(4n+2) {
          /* +2 because we have two additional list items with display:none */
          margin-right: 0;
        }
      }

      .cd-nav-item {
        border-bottom: none;
        padding: 0;
        height: auto;
        line-height: 1.2;

        img {
          position: static;
          margin-top: 0;
          height: auto;
          width: 100%;
          margin-bottom: .6em;
        }

        h3 {

          color: $secondary-font-color;
          font-weight: normal;
          padding: 0;
          margin: 0;
        }
      }
    }

    .cd-nav-icons {
      li {
        width: 100% * 0.23;
        float: left;
        margin: 0 2% 20px 0;
        i {
          float: left;
          line-height: 3em;
        }

        &:nth-child(3n+2) {
          /* +2 because we have two additional list items with display:none */
          margin-right: 0;
        }
      }

      .cd-nav-item {
        border-bottom: none;
        line-height: 1.2;
        padding: 24px 0 10px 10px;
        position: relative;

        &:hover {
          background: darken($secondary-background-color, 5%);
        }



        h3 {
          margin-left: 3em;
          font-size: 1em;
          color: $secondary-font-color;
          font-weight: bold;
        }

        p {
          margin-left: 3.32em;
          display: block;
        }

        &::before {
          left: 25px;
        }
      }
    }
  }
}

.has-children > a, .go-back a {
  position: relative;

  &::before, &::after {
    /* arrow icon in CSS - for element with nested unordered lists */
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -1px;
    display: inline-block;
    height: 2px;
    width: 10px;
    background: lighten($secondary-background-color, 10%);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  &::before {
    @include transform(rotate(45deg));
  }

  &::after {
    @include transform(rotate(-45deg));
  }

  @include media(min-width $large-screen) {
    &::before, &::after {
      background: darken($secondary-background-color, 10%);
    }

    &:hover::before, &:hover::after {
      background: $secondary-font-color;
    }
  }
}

.has-children > a {
  padding-right: 40px;

  &::before, &::after {
    /* arrow goes on the right side - children navigation */
    right: 20px;
    @include transform-origin(9px 50%);
  }
}

.cd-primary-nav .go-back a {
  padding-left: 40px;

  &::before, &::after {
    /* arrow goes on the left side - go back button */
    left: 20px;
    @include transform-origin(1px 50%);
  }
}

@include media(min-width $large-screen) {

  .has-children > a {
    &::before, &::after {
      right: 15%;
    }
  }

  .cd-primary-nav > .has-children > a {
    /* main navigation arrows on larger devices */
    padding-right: 30px !important;

    &::before, &::after {
      width: 9px;
      @include transform-origin(50% 50%);
      background: darken($secondary-background-color, 10%);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      @include transition(width .3s, transform .3s);
    }

    &::before {
      right: 12px;
    }

    &::after {
      right: 7px;
    }

    &.selected::before, &.selected::after {
      width: 14px;
    }

    &.selected::before {
      @include transform(translateX(5px) rotate(-45deg));
    }

    &.selected::after {
      @include transform(rotate(45deg));
    }
  }

  .cd-secondary-nav > .has-children > a::before, .cd-secondary-nav > .has-children > a::after {
    /* remove arrows on secondary nav titles */
    display: none;
  }

  .cd-primary-nav .go-back a {
    padding-left: 20px;

    &::before, &::after {
      left: 1px;
    }
  }
}

.cd-primary-nav {
  > .language-switcher {
    color: $secondary-font-color;
    @include media(max-width $large-screen - 1px) {
      position: fixed;
      width: 100%;
      bottom: 0;
      > a:first-child {
        border-top: 1px solid lighten($secondary-background-color, 5%);
      }
    }

    span {
      @include media(max-width $large-screen - 1px) {
        display: none;
      }
    }
    > a {
      padding: 0;
      @include media(max-width $large-screen - 1px) {
        padding: 0 20px ;
      }

      &.selected {
        box-shadow: none;
        font-weight: bold;
      }
    }
  }
}
.overlay {
  /* shadow layer visible when navigation is active */
  position: fixed;
  z-index: 2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  background-color: rgba($secondary-background-color, .8);
  visibility: hidden;
  opacity: 0;
  @include backface-visibility(hidden);
  @include transition(opacity .3s 0s, visibility 0s .3s, transform .3s 0s);

  &.is-visible {
    opacity: 1;
    visibility: visible;
    @include transition(opacity .3s 0s, visibility 0s 0s, transform .3s 0s);
  }

  @include media(max-width $large-screen - 1px) {
    &.is-visible {
      @include transform(translateX(-$navbar-width));
    }
    .nav-on-left &.is-visible {
      @include transform(translateX($navbar-width));
    }
    &.is-visible.search-is-visible, .nav-on-left &.is-visible.search-is-visible {
      @include transform(translateX(0));
    }
  }
}

/* --------------------------------

support for no js

-------------------------------- */

.no-js .cd-primary-nav {
  position: relative;
  height: auto;
  width: 100%;
  overflow: visible;
  visibility: visible;
  z-index: $content;
}

.no-js {
  position: relative;
  top: 0;
  opacity: 1;
  visibility: visible;
}

@include media(min-width $large-screen) {
  .no-js .cd-primary-nav {
    position: absolute;
    z-index: $above-content;
    display: inline-block;
    width: auto;
    top: 0;
    right: 150px;
    padding: 0;
  }

  .no-js .nav-is-fixed .cd-primary-nav {
    position: fixed;
  }
}
