html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.hidden {
  display: none!important;
}

section {
  @include outer-container(100%);
  background-color: $base-background-color;
  transform: translateZ(100px);

  &.more-info {
    padding: 4em 0 4em 0;
    margin: 2em 0 0 0;
    background-color: #f7f8fa;
    h1 {
      padding: 0 0 1em 0;
      background-color: #f7f8fa;
    }
  }
  .row-wrapper {
    @include row();
  }

  h1 {
    padding: 2em;
    text-align: center;
    margin: 0;
    font-weight: 300;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $base-background-color;
    transform: translateZ(100px);
    font-size: 2em;

    @include media(max-width $medium-screen) {
      font-size: 1.5em;
      padding: 1em 0.5em;
    }

    .line {
      width: 15%;
      height: 1px;
      margin: 0 1em;
      background-color: $base-font-color;
      @include media(max-width $medium-screen) {
        width: 40%;
      }
    }
  }

  article.item {
    @include keyframes("item-animation") {
      from {
        transform: translateX(-100vw);
      }
      to {
        transform: translateX(0);
      }
    };

    @include keyframes("item-animation-out") {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(100vw);
      }
    }
    margin: 0;
    padding: 1em;
    padding-top: 0;
    padding-bottom: 2em;
    @include span-columns(12);

    @include media(min-width $medium-screen) {
      @include span-columns(6);
    }
    @include media(min-width $large-screen) {
      @include span-columns(3);
    }

    figure {
      position: relative;
      overflow: hidden;
      img {
        vertical-align: top;
        @include transition(all 300ms ease-out);
      }
    }
    &:hover, &:focus {
      figcaption {
        opacity: 1;
      }

      img {
        transform: scale(1.25);
      }

      .line {
        @include transform(scale(1, 1));
      }
    }
    figcaption {
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $secondary-font-color;

      h3 {
        border-bottom: none;
        font-weight: 200;
        font-size: 1.4em;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 1px;
        margin: 5px;
      }

      a {
        margin: 5px;
        font-weight: 200;
      }

      .line {
        transform: scale(0, 0);
        width: 80%;
        height: 1px;
        background-color: $base-background-color;
        margin: 10px 0;
        @include transition(all 300ms ease-out);
      }
    }
  }
  article.info {
    @include span-columns(12);
    margin: 0;
    padding: 3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: 0.3s all linear;

    &:hover {
      .fa, h3 {
        transform: scale(1)
      }
    }

    .fa, h3 {
      transition: 0.3s all linear;
      transform: scale(0.8);
    }

    .fa {
      font-size: 5em;
    }

    h3 {
      margin-top: 25px;
      font-weight: 200;
    }

    @include media(min-width $medium-screen) {
      @include span-columns(6);
      display: flex;
    }
    @include media(min-width $large-screen) {
      @include span-columns(3);
      display: flex;
    }
  }
  .button-wrapper {
    @include span-columns(12);
    text-align: center;
    a {
      display: inline-block;
      padding: 1em;
      background-color: $base-background-color;
      color: $base-font-color;
      border: 1px solid $secondary-background-color;
      &:hover {
        animation: 200ms background-color ease-in;
        background-color: $secondary-background-color;
        color: $secondary-font-color;
      }
    }
  }
}

@include keyframes(background-color) {
  from {
    background-color: $base-background-color;
    color: $base-font-color;
  }
  to {
    background-color: $secondary-background-color;
    color: $secondary-font-color;
  }
}

footer {
  padding: 5em 0 0 0;
  text-align: center;
  background-color: $secondary-background-color;
  color: $secondary-font-color;
  font-weight: 200;
  @include outer-container(100%);

  section {
    @include row();
    padding-bottom: 25px;
    background-color: $secondary-background-color;
    color: $secondary-font-color;

    address, article {
      @include span-columns(12);
      margin: 0 0 1em 0;
      font-style: normal;
      padding: 0;
      &.footer-logo {
        @include media(max-width $large-screen - 1) {
          display: none;
        }
        h2 {
          margin: 0;
        }
      }
      @include media(min-width $medium-screen max-width $large-screen - 1) {
        @include span-columns(6);
        margin: 0 0 1em 0;
        &.contact {
          @include span-columns(6);
        }
      }
      @include media(min-width $large-screen) {
        @include span-columns(4);
        margin: 0;
        &.contact {
          @include span-columns(4);
        }
      }

      a {
        &.social {
          display: inline-block;
          padding: 0.5em;
          margin: 0.5em;
        }
        color: darken($secondary-font-color, 20%);
        &:hover {
          color: lighten($secondary-font-color, 10%);
        }
      }
      h2 {
        font-weight: 300;
        font-size: 2em;
      }
      h3 {
        font-weight: 200;
      }
    }
  }
  p {
    padding: 50px;
    margin: 0;
    letter-spacing: 1px;
  }
}