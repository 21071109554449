// Breakpoints
$medium-screen: 600px;
$large-screen: 1200px;
$font-breakpoint: 800px;

// Colors
$primary-color: white;
$secondary-color: #292d35;
$accent-color: #3f4652;
$success-color: #0e753b;
$base-background-color: $primary-color;
$secondary-background-color: $secondary-color;

// Typography
$logo-font-family: $silverthreadjf;
$base-font-family: $open-sans;
$heading-font-family: $base-font-family;

$base-font-size: 1em;
$base-font-size-mobile: 0.9em;

$base-line-height: 1.5;
$heading-line-height: 1.2;

$base-font-color: rgba(0,0,0,0.8);
$secondary-font-color: rgb(250,250,250);
$action-color: $secondary-color;
$error-color: #D50000;


// Other Sizes
$base-border-radius: 0px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Border
$base-border-color: lighten($secondary-background-color, 10%);
$base-border: 1px solid $base-border-color;

// Forms
$form-box-shadow: none;
$form-box-shadow-focus: none;

// Animations
$base-duration: 150ms;
$base-timing: ease;

// Navbar
$navbar-height-s: 50px;
$navbar-height-l: 80px;
$navbar-width: 260px;

// Z-index
$below-content: 1;
$content: 2;
$above-content: 3;
